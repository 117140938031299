<template>
  <div class="m-2 rounded-2xl">
    <div class="py-2 sm:px-6">
      <section>
        <div class="flex flex-col">
          <div class="flex items-end justify-between">
            <CashbillStat />
            <div class="flex flex-grow items-end justify-end gap-x-4">
              <div class="my-4 py-2">
                <base-checkbox
                  label="Tambah Point"
                  v-model="filter.is_prev_bonus"
                  v-on:change="handleChangeFilter"
                />
              </div>
              <base-input class="my-4" label="Pilih Stockist" with-label>
                <stockist-select-search
                  v-model="filter.destination_warehouse"
                  v-on:change="handleChangeFilter"
                />
              </base-input>
              <div class="my-4">
                <MitraPeriodMonthSelect
                  v-model="filter.periodId"
                  v-on:change="handleChangeFilter"
                />
              </div>
              <div class="relative my-4 w-2/12 rounded-md shadow-sm">
                <label
                  for="status_faktur"
                  class="mb-2 block text-sm font-medium text-gray-700"
                  >Status Faktur</label
                >
                <base-select
                  :expand="false"
                  v-model="filter.is_valid_for_payment"
                  @change="handleChangeFilter"
                  id="destination"
                >
                  <option :value="''">Pilih Status</option>
                  <option :value="null">Semua</option>
                  <option :value="true">Valid</option>
                  <option value="reported">Valid Laporan</option>
                  <option :value="false">Draft</option>
                  <option value="cancel">Cancel</option>
                  <option value="deleted">Hapus</option>
                </base-select>
              </div>
              <base-search
                label="Search"
                class="my-4"
                v-model="search"
                @input="debounceSearch"
                placeholder="Cari kode atau nama"
              />
            </div>
          </div>
          <div class="my-2 -flex sm:-mx-6 lg:-mx-8">
            <div class="min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <CashbillTable
                @page-change="onPageChangeOrder"
                @click-row="changeSale"
              />
            </div>
          </div>
        </div>
      </section>

      <CashbillDetailModal
        :order="dataPenjualan"
        :office="getOffice"
        :visible="openDetail"
        :with-validation="false"
        :editable="false"
        :deleteable="false"
        @close="openDetail = false"
        @refresh="handleRefresh"
        @edit="openEditOrder"
        @delete="confirmationDelete = true"
      />

      <!--DELETE MODAL CONFIRMATION-->
      <transition name="fade">
        <Modal2
          v-if="confirmationDelete"
          @close-modal="confirmationDelete = false"
          colorheading="bg-red-50"
          colorbody="bg-red-50"
        >
          <template v-slot:heading>
            <h3
              class="text-lg font-semibold leading-6 text-gray-900"
              id="modal-title "
            >
              Hapus faktur penjualan
            </h3>
          </template>
          <template v-slot:body>
            <div
              class="flex relative content-center items-center justify-center py-2"
            >
              <svg
                class="text-red-100"
                width="194"
                height="191"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M108.028 1.515c26.059 5.163 34.467 35.206 50.246 56.51 14.48 19.55 37.067 35.218 35.664 59.477-1.648 28.473-17.071 57.204-43.101 69.053-25.607 11.655-53.413-2.016-79.137-13.413-27.257-12.077-58.095-23.354-67.512-51.561-10.255-30.716-.982-65.558 20.224-90.077C44.76 7.977 77.462-4.541 108.028 1.515Z"
                  fill="currentColor"
                />
              </svg>
              <div class="flex absolute w-full justify-center">
                <DocumentDelete class="h-32" />
              </div>
            </div>
            <h3 class="text-lg leading-6 text-gray-900" id="modal-title">
              Faktur penjualan dengan Nomor
              <span class="font-bold">{{
                dataPenjualan.attributes.origin_code
              }}</span>
              akan dihapus. Apakah anda yakin ingin melanjutkan?
            </h3>
          </template>
          <template v-slot:footer>
            <div>
              <button
                @click="deleteOrder"
                class="focus:outline-none w-full shrink justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
              >
                Ya, saya yakin
              </button>
              <button
                @click="confirmationDelete = !confirmationDelete"
                class="bg-white-600 inline-flex w-full justify-center rounded-md px-4 py-2 text-base font-medium text-gray-400 hover:bg-white hover:text-blue-400 sm:text-sm"
              >
                Kembali
              </button>
            </div>
          </template>
        </Modal2>
      </transition>

      <transition name="fade">
        <Modal2
          v-if="deleteStatus"
          @close-modal="deleteStatus = false"
          :footer="false"
          colorheading="bg-red-50"
          colorbody="bg-red-50"
        >
          <template v-slot:heading> </template>
          <template v-slot:body>
            <div
              class="flex relative content-center items-center justify-center py-2"
            >
              <svg
                class="text-red-100"
                width="194"
                height="191"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M108.028 1.515c26.059 5.163 34.467 35.206 50.246 56.51 14.48 19.55 37.067 35.218 35.664 59.477-1.648 28.473-17.071 57.204-43.101 69.053-25.607 11.655-53.413-2.016-79.137-13.413-27.257-12.077-58.095-23.354-67.512-51.561-10.255-30.716-.982-65.558 20.224-90.077C44.76 7.977 77.462-4.541 108.028 1.515Z"
                  fill="currentColor"
                />
              </svg>
              <div class="flex absolute w-full justify-center">
                <DocumentSuccess class="h-32" />
              </div>
            </div>
            <h3 class="text-lg leading-6 text-gray-900" id="modal-title">
              Faktur penjualan dengan Nomor
              <span class="font-bold">{{
                dataPenjualan.attributes.origin_code
              }}</span>
              berhasil dihapus.
            </h3>
          </template>
        </Modal2>
      </transition>

      <!-- <transition name="fade">
        <Modal2 v-if="confirmationEdit" @close-modal="confirmationEdit = false">
          <template v-slot:header>
            <h3
              class="text-lg font-semibold leading-6 text-gray-900"
              id="modal-title"
            >
              Edit Faktur Penjualan
            </h3>
          </template>
          <template v-slot:footer>
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
              <button
                type="button"
                @click="editOrder(dataPenjualan)"
                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
              >
                Ya
              </button>
              <button
                @click="confirmationEdit = !confirmationEdit"
                type="button"
                class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
              >
                Tutup
              </button>
            </div>
          </template>
        </Modal2>
      </transition> -->

      <!-- MODAL EDIT CONFIRMATION-->
      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="confirmationEdit"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            >
              <div>
                <div
                  class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
                >
                  <!-- Heroicon name: outline/check -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    class="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Edit Faktur Penjualan
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Faktur Penjualan yang lama akan dihapus dan diganti faktur
                      yang baru. Apakah anda yakin?
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                <button
                  type="button"
                  @click="editOrder(dataPenjualan)"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                >
                  Ya
                </button>
                <button
                  @click="confirmationEdit = !confirmationEdit"
                  type="button"
                  class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import dayjs from 'dayjs';
import Modal2 from '@/components/Modal2.vue';
import { StorageService } from '@/services/storage.service';
import { DocumentSuccess, DocumentDelete } from '@/assets/icons';
import {
  getListMonths,
  getListPastNYear,
  getCurrentDate,
} from '@/services/date.service.js';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import CashbillDetailModal from '@/components/cash-bill/CashbillDetailModal.vue';
import CashbillStat from '@/components/cash-bill/CashbillStat.vue';
import MitraPeriodMonthSelect from '@/components/period/mitra-period/mitra-period-month-select.vue';
import StockistSelectSearch from '@/components/stockist/stockist-select-search.vue';
import BaseSearch from '@/components/base/Search.vue';
import CashbillTable from '@/components/cash-bill/CashbillTable.vue';

export default {
  name: 'Home',
  components: {
    Modal2,
    BaseSearch,
    DocumentSuccess,
    BaseInput,
    DocumentDelete,
    BaseCheckbox,
    CashbillDetailModal,
    CashbillStat,
    MitraPeriodMonthSelect,
    StockistSelectSearch,
    CashbillTable,
  },

  data() {
    return {
      filter: {
        month: getCurrentDate().month,
        year: getCurrentDate().year,
        is_valid_for_payment: false,
        bonus_month: null,
        is_prev_bonus: false,
        periodId: null,
        destination_warehouse: null,
      },
      search: null,
      openDetail: false,
      confirmationDelete: false,
      deleteStatus: false,
      confirmationEdit: false,
      dataPenjualan: {},
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      getOffice: 'offices/getOffice',
      isLoading: 'orders/getLoading',
    }),
    ordersParams() {
      return {
        'page[limit]': 5,
        // 'filter[is_prev_bonus]': this.filter.is_prev_bonus,
        'filter[order_status]': this.filter.order_status,
        'filter[search]': this.search,
        'filter[destination_office_category_id]': 5,
        'filter[mitra_period_uuid]': this.filter.periodId,
        include: 'destination-office,origin-office,order',
        fields: {
          offices: 'name,code,address,address2,referred_by,referred_by_name',
          orders:
            'origin_office_code,destination_office_code,order_status,is_valid_for_packing,is_valid_for_shipment,is_shipped,is_completed,has_follow_up_delivery,is_pending_follow_up_delivery,updatedAt,final_price,destination-office,origin-office,origin_code,destination_code,is_bonus_reported,grand_total_weight,order_status,createdAt,is_prev_bonus,total_point_value,total_bonus_value',
        },
      };
    },
    availableMonthOptions: function () {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...getListMonths().map((month, index) => ({
          key: month,
          value: index + 1,
          label: month,
        })),
      ];
    },
    availablYearOptions: function () {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...getListPastNYear().map((year) => ({
          key: year,
          value: year,
          label: year,
        })),
      ];
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrder: 'orders/fetchOrder',
      fetchOrders: 'orders/fetchOrders',
      updateOrder: 'orders/updateOrder',
      duplicateOrder: 'orders/duplicateOrder',
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchCurrentPeriod: 'mitra_periods/fetchCurrentMitraPeriod',
    }),
    handleChangeFilter() {
      this.fetchOrders({
        ...this.ordersParams,
      });
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.ordersParams,
      });
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...this.ordersParams,
        pageNumber: page,
      });
    },
    changeSale(data) {
      this.openDetail = !this.openDetail;
      Promise.all([
        this.fetchOrder({
          id: data.attributes.order_id,
          fields: {
            'order-details':
              'total_price,product_qty,product_weight,total_weight,product_name,product_code',
          },
        }),
        this.fetchOfficeById({
          office_id: data.relationships['destination-office'].data.id,
          params: {
            include: 'uplines,upline-relations',
          },
        }),
      ]).then(() => {
        this.dataPenjualan = this.getOrder.data;
      });
    },
    openEditOrder() {
      if (
        this.dataPenjualan.attributes.canceled_from &&
        this.dataPenjualan.attributes.canceled_by_user_id
      ) {
        this.confirmationEdit = !this.confirmationEdit;
      } else {
        this.$router.push(
          `/cashbill/transaksi-cashbill/${this.dataPenjualan.id}`
        );
      }
    },
    validasi(data) {
      const payload = {
        data: {
          type: 'orders',
          id: data.id,
          attributes: {
            is_valid_for_payment: true,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.openDetail = false;
          this.fetchOrders({
            origin_warehouse_id: StorageService.getUser().current_warehouse,
            pageNumber: 1,
            pageSize: 5,
            destination_office_category: 'Member',
            fields: this.fields,
          });
        }
      });
    },
    deleteOrder() {
      const payload = {
        data: {
          type: 'orders',
          id: this.dataPenjualan.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };

      this.updateOrder(payload).then((response) => {
        this.confirmationDelete = !this.confirmationDelete;
        this.deleteStatus = true;
        if (response) {
          this.fetchOrders({
            ...this.ordersParams,
          });
          this.openDetail = !this.openDetail;
        } else {
          this.failed = true;
        }
      });
    },
    editOrder(data) {
      this.duplicateOrder({
        id: data.id,
      }).then((response) => {
        if (response) {
          this.$router.push(
            `/penjualan/transaksi-penjualan/${response.data.data.id}`
          );
        }
      });
    },
    cetakInvoice() {
      this.$refs.html2Pdf.generatePdf();
    },
    handleRefresh: function () {
      this.fetchOrders({
        ...this.ordersParams,
      });
    },
    async loadCurrentPeriod() {
      const res = await this.fetchCurrentPeriod();

      this.filter.periodId = res.data.data.id;
    },
  },
  async created() {
    await this.loadCurrentPeriod();

    this.fetchOrders({
      ...this.ordersParams,
    });

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
