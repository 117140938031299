import { render, staticRenderFns } from "./tambah-mitra-usaha.vue?vue&type=template&id=49ac4036"
import script from "./tambah-mitra-usaha.vue?vue&type=script&lang=js"
export * from "./tambah-mitra-usaha.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports