<template>
  <base-wrapper>
    <div class="grid grid-cols-1 gap-4">
      <base-card title="Tambah / Update Mitra Usaha">
        <div class="grid grid-cols-1 gap-4">
          <base-input
            label="File Mitra Usaha Untuk Ditambahkan"
            type="file"
            :shadow="false"
            size="padding-md"
            fullwidth
            accept=".csv"
            @change="onChangeFile"
          />
          <div class="col-span-full">
            <base-button :loading="loading" @click="onProcess">{{
              statusMessage
            }}</base-button>
          </div>
        </div>
      </base-card>
      <base-card title="Keterangan">
        <dl class="grid gap-4">
          <div class="w-full">
            <dt class="text-sm text-gray-900">Contoh file mitra usaha untuk ditambah / diupdate</dt>
            <dd class="mt-1">
              <a href="/tool-bonus-import-example/tambah-update-mitra-usaha.csv" class="text-sm text-green-600 font-medium">tambah-update-mitra-usaha.csv</a>
            </dd>
          </div>
        </dl>
      </base-card>
    </div>
    <base-confirm
      message="Apakah anda yakin akan menambahkan mitra usaha yang diupload?"
      :visible="confirming"
      @close="confirming = false"
      @confirmed="onConfirmed"
    ></base-confirm>
  </base-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import { validateFileCsv } from '@/utils/misc';

export default {
  mixins: [requestMixin],
  components: { BaseConfirm },
  data() {
    return {
      loading: false,
      confirming: false,
      status: 'null',
      form: {
        file: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    statusMessage() {
      return {
        null: 'Proses',
        request: 'Mengirim ke Server',
        wait: 'Menunggu Diproses',
        queued: 'Menunggu Antrian',
        started: 'Mulai Diproses',
        'warehouse-importing': 'Menambahkan Gudang',
        'member-importing': 'Menambahkan Kantor',
        'member-warehouse-importing': 'Menambahkan Kantor Gudang',
        'user-importing': 'Menambahkan Akun',
        'upline-importing': 'Menambahkan Upline',
        'role-importing': 'Menambahkan Role',
        'pin-hashing': 'Menenkripsi PIN',
        finished: 'Selesai',
      }[this.status];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    listenToEvent() {
      const channel = this.$pusher.subscribe(
        `private-OfficeBulkCreateMitraUsaha.${this.me.id}`
      );

      channel.bind('OfficeBulkCreateMitraUsahaStatusUpdated', (e) => {
        if (e.status === 'error') {
          this.setStatus('null');
          this.stopLoading();
          this.createAlert({
            data: e.message,
            status: 'error',
          });
        } else if (e.status === 'finished') {
          this.setStatus('null');
          this.stopLoading();
          this.createAlert({
            data: 'Berhasil menambahkan mitra usaha',
            status: 'success',
          });
        } else {
          this.status = e.status;
        }
      });
    },
    onChangeFile(e) {
      this.form.file = e.target.files[0];
    },
    async onProcess() {
      if (!validateFileCsv(this.form.file)) {
        this.createAlert({
          data: 'File yang diupload harus bertipe csv',
          status: 'error',
        });
      } else {
        this.confirming = true;
      }
    },
    async onConfirmed() {
      this.confirming = false;

      this.setStatus('request');
      this.startLoading();

      const formData = new FormData();

      formData.append('file', this.form.file);

      const [, error] = await this.request(
        '/api/v1/offices/-actions/bulk-create-mitra-usaha',
        {
          method: 'post',
          data: formData,
        }
      );

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
          status: 'error',
        });
        this.setStatus('null');
        this.stopLoading();
      } else {
        this.setStatus('wait');
      }
    },
    setStatus(status) {
      this.status = status;
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    stopListenToEvent() {
      if (this.me) {
        this.$pusher.unsubscribe(`private-OfficeBulkCreateMitraUsaha.${this.me.id}`);
      }
    },
  },
  created() {
    this.listenToEvent();
  },
  destroyed() {
    this.stopListenToEvent();
  },
};
</script>
